import Swiper, { Autoplay, EffectCoverflow, Pagination } from "swiper";

(function () {
  /**
   * Agregar fecha actual a la línea de copyright.
   */
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentYearElement = document.getElementById("current-year");
  currentYearElement.innerHTML = currentYear.toString();

  /**
   * Agregar años de servicio.
   */
  const currentMonth = date.getMonth() + 1; // Los meses en JavaScript van de 0 a 11

  let experienceYears = currentYear - 2005;

  // Si el mes actual es anterior al mes de inicio, resta un año
  if (currentMonth < 5) {
    experienceYears--;
  }

  const goldenNumbersElement = document.getElementById("golden-numbers");
  goldenNumbersElement.innerHTML = experienceYears.toString();

  /**
   * Mostrar u ocultar navbar al hacer scroll.
   */
  let prevScrollpos = window.pageYOffset;
  let currentScrollPos = 0;
  const navbarElement = document.getElementById("navbar");

  window.onscroll = function () {
    currentScrollPos = window.pageYOffset;
    navbarElement.classList.toggle("hide", currentScrollPos > prevScrollpos);
    prevScrollpos = currentScrollPos;
  };

  /**
   * Mostrar u ocultar el sidenav.
   */
  const sidenavElement = document.getElementById("sidenav");
  document
    .getElementById("open-sidenav-btn")
    .addEventListener("click", openSidenav, false);
  sidenavElement.addEventListener("click", closeSidenav, false);

  function openSidenav() {
    sidenavElement.classList.add("open");
  }
  function closeSidenav() {
    sidenavElement.classList.remove("open");
  }

  /**
   * Efecto flip a las tarjetas de servicio.
   */
  const services = Array.from(document.getElementsByClassName("service"));
  services.forEach((service) => {
    service.onclick = function ($event) {
      $event.preventDefault();
      $event.currentTarget.classList.toggle("flip");
    };
  });

  /**
   * Inicializar el Swiper.
   * @see https://swiperjs.com/api/
   */
  Swiper.use([Autoplay, EffectCoverflow, Pagination]);
  const mySwiper = new Swiper(".swiper-container", {
    roundLengths: true,
    effect: "coverflow",
    slidesPerView: "auto",
    centeredSlides: true,
    slideToClickedSlide: true,
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  });
})();
